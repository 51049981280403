import React, { useEffect, useState } from 'react';
import { Route } from "react-router-dom";
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';

import UsersList from "./components/User/UsersList";
import User from "./components/User/User";
import Course from "./components/Course/Course";
import CoursesList from "./components/Course/CoursesList";
import AddCourse from "./components/Course/AddCourse";
import SchoolsList from "./components/School/SchoolsList";
import School from "./components/School/School";
import Class from "./components/Class/Class";
import ClassesList from "./components/Class/ClassesList";
import AddClass from "./components/Class/AddClass";
import NavbarAll from './components/Navbar/Navbar';
import UploadExcel from './components/Registration/UploadExcel';
import Region from "./components/Region/Region";
import AddRegion from "./components/Region/AddRegion";
import RegionsList from "./components/Region/RegionsList";
import CitiesList from "./components/City/CitiesList";
import ServiceRole from "./components/ServiceRole/ServiceRole";
import AddServiceRole from "./components/ServiceRole/AddServiceRole";
import ServiceRolesList from './components/ServiceRole/ServiceRolesList';

import CityDataService from "./services/CityService";
import DistrictDataService from "./services/DistrictService";
import RegionDataService from "./services/RegionService";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import './custom.css';

export const RegionsContext = React.createContext();
export const DistrictsContext = React.createContext();
export const CitiesContext = React.createContext();

export default function App() {

  const [regions, setRegions] = useState();
  const [districts, setDistricts] = useState();
  const [cities, setCities] = useState();

  useEffect(() => {
    retrieveAll( RegionDataService, setRegions );
    retrieveAll( DistrictDataService, setDistricts );
    retrieveAll( CityDataService, setCities );
  }, []);

  const retrieveAll = ( dataService, setState ) => {
      dataService.getAll()
      .then((response) => {
        setState(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return ( 
    <RegionsContext.Provider value={{regions, setRegions}}>
    <CitiesContext.Provider value={{cities, setCities}}>
    <DistrictsContext.Provider value={{districts, setDistricts}}>
      <NavbarAll/>
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
        <AuthorizeRoute exact path={["/users"]} component={UsersList} />
        <AuthorizeRoute exact path="/users/:id" component={User} />
        <AuthorizeRoute exact path={["/courses"]} component={CoursesList} />
        <AuthorizeRoute exact path="/courses/:id" component={Course} />
        <AuthorizeRoute exact path="/courses/:id/classes" component={AddClass} />
        <AuthorizeRoute exact path={["/classes"]} component={ClassesList} />
        <AuthorizeRoute exact path="/classes/:id" component={Class} />
        <AuthorizeRoute exact path="/classes/:id/registrations" component={UploadExcel} />
        <AuthorizeRoute exact path={["/add/course"]} component={AddCourse} />
        <AuthorizeRoute exact path={["/school/"]} component={School} />
        <AuthorizeRoute exact path={["/add/class"]} component={AddClass} />
        <AuthorizeRoute exact path={["/schools"]} component={SchoolsList} />
        <AuthorizeRoute exact path="/school/:id" component={School} />
        <AuthorizeRoute exact path={["/cities"]} component={CitiesList} />
        <AuthorizeRoute exact path={["/regions"]} component={RegionsList} />
        <AuthorizeRoute exact path="/regions/:id" component={Region} />
        <AuthorizeRoute exact path="/add/region" component={AddRegion} />
        <AuthorizeRoute exact path={["/serviceRoles"]} component={ServiceRolesList} />
        <AuthorizeRoute exact path="/serviceRoles/:id" component={ServiceRole} />
        <AuthorizeRoute exact path="/add/serviceRole" component={AddServiceRole} />
      </Layout>
    </DistrictsContext.Provider>
    </CitiesContext.Provider>
    </RegionsContext.Provider>
  );

}
